.basket {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  min-height: 100%;
  text-align: center;

  > ion-list {
    flex-grow: 1;
  }
}

.save_btn {
  margin: 0.2rem;
}

.scanner_btn {
  bottom: 4em;
  right: 1.4em;
}

.basket_item {
  display: grid;
  grid-template-columns: 12fr minmax(40px, 1fr) minmax(40px, 1fr);
  gap: 0.5em;
  width: 100%;

  > :first-child {
    white-space: normal;
  }
}
