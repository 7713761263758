.qr_code_page {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;

  > *:not(.content) {
    flex-shrink: 0;
  }

  ion-button {
    margin: 0.2rem;
  }
}

.content {
  display: flex;
  flex-direction: column;
  margin: auto;
  padding-bottom: 4em;
  overflow: auto;

  h2 {
    margin-bottom: 0.75em;
    text-align: center;
  }
}

.qr_code_container {
  margin: auto;
  overflow: auto;
  color: #000;
  text-align: center;
  background-color: #fff;
}
