.group {
  background: var(--ion-item-background, var(--ion-background-color, #fff));

  p {
    margin: 0;
    padding: 0.5em 1em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
