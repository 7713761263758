.scanner {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  min-height: 100%;
  text-align: center;
}

.tips_and_manual {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 1rem 0.2rem 0.2rem;
}

.tips {
  display: inline-block;
  margin: auto;
}
